import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'gatsby'
import tw, { styled } from "twin.macro"
import { Collapse } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { LanguageSelector } from 'src/components/LanguageSelector'
import Divider from '@material-ui/core/Divider';
import {Helmet} from 'react-helmet'


const StyledBurger = styled.button`
  position: absolute;
  top: 0.1rem;
  right: 0.2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  transform: scale(0.7);

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background: ${({ open }) => open ? '#0D0C1D' : '#8d929c'};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :nth-child(1) {
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }

    :nth-child(2) {
      opacity: ${({ open }) => open ? '0' : '1'};
      transform: ${({ open }) => open ? 'translateX(20px)' : 'translateX(0)'};
    }

    :nth-child(3) {
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
  @media screen and (min-width: 800px){
    display: none;
  }
`

const HeaderWrapper = styled.header`
z-index: 100;
  font-family: Cinzel;
  width: -moz-fit-content;
  width: fit-content;
  background-color: white;
  margin: auto;
  margin-bottom: 14em; /*was 11*/
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  position: relative;
  color: #8d929c;
  h1{
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
  margin-top: 0.5em;
  padding: 0.1em 3em 0.2em 3em;
  @media screen and (min-width: 800px){
  margin-top: 2em;
  height: 3em;
  height: 7em;
  padding: 1em 2em 2em 2em;
  h1{
      font-size: 2rem;
      line-height: 2rem;
    }
  }
`

const HeaderNav = styled.nav`
display: ${({open})=> open? `flex;`:`none;`}
@media screen and (min-width: 800px){

  display: flex;
}
  justify-content: space-around;
`

const HeaderLink = styled(Link)`
￼    padding: 0.2rem 0.3rem;
`

const Popup = styled.div`
${tw`flex flex-col w-9/12 mx-auto shadow-sm bg-white pb-8 pt-2 px-2`}
box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
`

const ButtonWrap = styled.div`
${tw`flex flex-col flex-1 text-center cursor-pointer`}
display:none;
@media screen and (min-width: 800px){
  display: block;
}
`

const Title = styled.h1`
a{text-decoration: none;
		color: #8d929c;
		transition: all 0.1s;
}${tw`mb-4 text-center`}
font-weight: normal;
a:hover{
  text-decoration: underline;
}
`

const MobileNav = styled.nav`
${tw`shadow-xl bg-white px-4 pt-2 pb-8 fixed top-0 left-0`}
a{
  display: block;
  padding: 0.1rem 0;
}
@media screen and (min-width: 500px){
  left:50%;
  transform: translate(-50%);
}
 @media screen and (min-width: 800px){
   display: none;
 }
`


export const Header = ({ location }) => {
  const isFrench = location.pathname.match(/\/fr\//)
  const [loaded, setLoaded] = useState(false)

  const [open, setOpen] = useState(false)
  const [openHistory, setOpenHistory] = useState(false)
  const [openDomain, setOpenDomain] = useState(false)
  const [openEvents, setOpenEvents] = useState(false)
  const historyNode = useRef()
  const domainNode = useRef()
  const eventsNode = useRef()
  
  useEffect(() => {
    setLoaded(true)
  }, [])


  const handleClick = e => {
    if (historyNode.current.contains(e.target)) {
      return;
    } else {
      setOpenHistory(false)
    }
    if (domainNode.current.contains(e.target)) {
      return;
    } else {
      setOpenDomain(false)
    }
    if (eventsNode.current.contains(e.target)) {
      return;
    } else {
      setOpenEvents(false)
    }
  };
  return loaded ? (
    <>
    <Helmet>
          <meta charSet="utf-8" />
          <title>Château de Fertot</title>
          <link rel="canonical" href="http://www.chateaudefertot.net" />
          <meta name="description" content="Château de Fertot is a historical monument with a 25 acres park designed by Paul de Lavenne, the comte de Choulot." />
        </Helmet>
    <HeaderWrapper>
      <Title>
        <a href="/">Château de Fertot</a>
      </Title>
      <StyledBurger className="lol" open={open} onClick={() => setOpen(!open)} >
      <div />
      <div />
      <div /></StyledBurger>
      <LanguageSelector location={location} />
      <HeaderNav open={open}>
        <MobileNav open={open}>
        <Collapse in={open}><HeaderLink to={isFrench ? '/fr/history/history' : '/en/history/history'}>{!isFrench ? "History" : "Histoire"}</HeaderLink>
              <HeaderLink to={isFrench ? '/fr/history/beginning' : '/en/history/beginning'}>{!isFrench ? "The beginning":"Le début"}</HeaderLink>
              <HeaderLink to={isFrench ? '/fr/history/lastcentury' : '/en/history/lastcentury'}>{!isFrench ? "Last century" : "Le siècle dernier"}</HeaderLink>
              <HeaderLink to={isFrench ? '/fr/history/present' : '/en/history/present'}>{!isFrench ? "The present" : "Le present"}</HeaderLink>
              <Divider/>
              <HeaderLink to={isFrench ? '/fr/domain/domain' : '/en/domain/domain'}>{!isFrench ? "Domain":"Domaine"}</HeaderLink>
              <HeaderLink to={isFrench ? '/fr/domain/chateau' : '/en/domain/chateau'}>Château</HeaderLink>
              <HeaderLink to={isFrench ? '/fr/domain/park' : '/en/domain/park'}>{!isFrench ? "Park": "Parc"}</HeaderLink>
              <HeaderLink to={isFrench ? '/fr/domain/chapel' : '/en/domain/chapel'}>{!isFrench ? "Chapel" : "Chapelle"}</HeaderLink>
              <HeaderLink to={isFrench ? '/fr/domain/potager' : '/en/domain/potager'}> Potager</HeaderLink>
              <HeaderLink to={isFrench ? '/fr/domain/prosliers' : '/en/domain/prosliers'}>Les Prosliers</HeaderLink>
              <HeaderLink to={isFrench ? '/fr/domain/courtyard' : '/en/domain/courtyard'}>{!isFrench ? "Farm courtyard":"Cour de la ferme"}</HeaderLink>
              <Divider/>
              <HeaderLink to={isFrench ? '/fr/events/events' : '/en/events/events'}>{!isFrench ? "Events": "Événements"}</HeaderLink>
              <HeaderLink to={isFrench ? '/fr/events/dongiovanni2017' : '/en/events/dongiovanni2017'}>Don Giovanni 2017</HeaderLink>
              <HeaderLink to={isFrench ? '/fr/events/onegin2018' : '/en/events/onegin2018'}>{!isFrench ? "Eugene Onegin " : "Eugène Onéguine "} 2018</HeaderLink>
              <HeaderLink to={isFrench ? '/fr/events/madamebutterfly2019' : '/en/events/madamebutterfly2019'}>Madame Butterfly 2019</HeaderLink>
              <HeaderLink to={isFrench ? '/fr/events/recitalprestige' : '/en/events/recitalprestige'}>Jean-Marc Luisada 2020</HeaderLink>
              <HeaderLink to={isFrench ? '/fr/events/carmen2021' : '/en/events/carmen2021'}>Carmen 2021</HeaderLink>
              <HeaderLink to={isFrench ? '/fr/events/tosca2022' : '/en/events/tosca2022'}>Tosca 2022</HeaderLink></Collapse>

        </MobileNav>
        <ButtonWrap ref={historyNode}>
          <div className="cursor-pointer" onClick={() => {
            setOpenEvents(false)
            setOpenDomain(false)
            setOpenHistory(!openHistory)
          }}>
            {openHistory ? <ExpandMoreIcon /> : !isFrench ? `History` : `Histoire`
            }
          </div>
          <Collapse in={openHistory}>
            <Popup><HeaderLink to={isFrench ? '/fr/history/history' : '/en/history/history'}>{!isFrench ? "History" : "Histoire"}</HeaderLink>
              <HeaderLink to={isFrench ? '/fr/history/beginning' : '/en/history/beginning'}>{!isFrench ? "The beginning":"Le début"}</HeaderLink>
              <HeaderLink to={isFrench ? '/fr/history/lastcentury' : '/en/history/lastcentury'}>{!isFrench ? "Last century" : "Le siècle dernier"}</HeaderLink>
              <HeaderLink to={isFrench ? '/fr/history/present' : '/en/history/present'}>{!isFrench ? "The present" : "Le present"}</HeaderLink></Popup>
          </Collapse>
        </ButtonWrap>
        <ButtonWrap ref={domainNode}>
          <div className="cursor-pointer" onClick={() => {
            setOpenEvents(false)
            setOpenHistory(false)
            setOpenDomain
              (!openDomain)
          }}>
            {openDomain ? <ExpandMoreIcon /> : !isFrench ? `Domain` : `Domaine`
            }</div>
          <Collapse in={openDomain}>
            <Popup><HeaderLink to={isFrench ? '/fr/domain/domain' : '/en/domain/domain'}>{!isFrench ? "Domain":"Domaine"}</HeaderLink>
              <HeaderLink to={isFrench ? '/fr/domain/chateau' : '/en/domain/chateau'}>Château</HeaderLink>
              <HeaderLink to={isFrench ? '/fr/domain/park' : '/en/domain/park'}>{!isFrench ? "Park": "Parc"}</HeaderLink>
              <HeaderLink to={isFrench ? '/fr/domain/chapel' : '/en/domain/chapel'}>{!isFrench ? "Chapel" : "Chapelle"}</HeaderLink>
              <HeaderLink to={isFrench ? '/fr/domain/potager' : '/en/domain/potager'}> Potager</HeaderLink>
              <HeaderLink to={isFrench ? '/fr/domain/prosliers' : '/en/domain/prosliers'}> Les Prosliers</HeaderLink>
              <HeaderLink to={isFrench ? '/fr/domain/courtyard' : '/en/domain/courtyard'}>{!isFrench ? "Farm courtyard":"Cour de la ferme"}</HeaderLink></Popup>
          </Collapse>
        </ButtonWrap>
        <ButtonWrap ref={eventsNode}>
          <div className="cursor-pointer" onClick={() => {
            setOpenHistory(false)
            setOpenDomain(false)
            setOpenEvents
              (!openEvents)
          }}>
            {openEvents ? <ExpandMoreIcon /> : !isFrench ? `Events`: "Événements"}</div>
          <Collapse in={openEvents}>
            <Popup><HeaderLink to={isFrench ? '/fr/events/events' : '/en/events/events'}>{!isFrench ? "Events": "Événements"}</HeaderLink>
              <HeaderLink to={isFrench ? '/fr/events/dongiovanni2017' : '/en/events/dongiovanni2017'}>Don Giovanni 2017</HeaderLink>
              <HeaderLink to={isFrench ? '/fr/events/onegin2018' : '/en/events/onegin2018'}>{!isFrench ? "Eugene Onegin " : "Eugène Onéguine "} 2018</HeaderLink>
              <HeaderLink to={isFrench ? '/fr/events/madamebutterfly2019' : '/en/events/madamebutterfly2019'}>Madame Butterfly 2019</HeaderLink>
              <HeaderLink to={isFrench ? '/fr/events/recitalprestige' : '/en/events/recitalprestige'}>Jean-Marc Luisada 2020</HeaderLink>
              <HeaderLink to={isFrench ? '/fr/events/carmen2021' : '/en/events/carmen2021'}>Carmen 2021</HeaderLink>
              <HeaderLink to={isFrench ? '/fr/events/tosca2022' : '/en/events/tosca2022'}>Tosca 2022</HeaderLink></Popup>
          </Collapse>
        </ButtonWrap>
      </HeaderNav>
    </HeaderWrapper>
    </>
  ) : <div></div>
}
