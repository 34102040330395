import React from 'react'
import styled from 'styled-components'
const StyledFooter = styled.footer`
	padding: 2em 0 2em 0;
  p{
    text-align: center;
  }
  position: relative;
`


const Me = styled.p`
  position: absolute;
  right: 0.2rem;
  bottom: 0.2rem;
  font-size: 0.8rem;
  font-style: italic;
  margin: 0;
`


export const Footer = () => {
  return (
    <StyledFooter>
            <p>&copy; Château de Fertot - 2018</p>
            <Me><a href="mailto:jenninigs.laurence@gmail.com">website by LJ</a></Me>
          </StyledFooter>
  )
}
