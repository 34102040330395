import React from 'react'
import styled from 'styled-components'
import {Link} from 'gatsby'
const FlagWrapper = styled.div`
  position: absolute;
  right: 0.5rem;
  bottom: 0.5rem 
`

const France = styled.div`
width: 30px;
height: 20px;
overflow: hidden;
border: 1px solid #efefef;
  background: linear-gradient(to right, #002395 calc(100% / 3), white calc(100% / 3), white calc(100% / 3 * 2), #ED2939 calc(100% / 3 * 2))

`

const UK = styled.div`
width: 30px;
height: 20px;
overflow: hidden;
border: 1px solid #efefef;

  background: #00247D;
  background-size: 100px 100px;
  position: relative; 

&:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  background: linear-gradient(to bottom, transparent 40%, #CC142B 40%, #CC142B 60%, transparent 60%), linear-gradient(to right, transparent 45%, #CC142B 45%, #CC142B 55%, transparent 55%), linear-gradient(to bottom, transparent 35%, white 35%, white 65%, transparent 65%), linear-gradient(to right, transparent 40%, white 40%, white 60%, transparent 60%), linear-gradient(146deg, transparent 50%, #CC142B 50%, #CC142B 53%, transparent 53%) -65px 45px, linear-gradient(146deg, transparent 50%, #CC142B 50%, #CC142B 53%, transparent 53%) 55px -45px, linear-gradient(34deg, transparent 50%, #CC142B 50%, #CC142B 53%, transparent 53%) -75px -43px, linear-gradient(34deg, transparent 50%, #CC142B 50%, #CC142B 53%, transparent 53%) 70px 46px, linear-gradient(146deg, transparent 45%, white 45%, white 55%, transparent 55%), linear-gradient(34deg, transparent 45%, white 45%, white 55%, transparent 55%);
  width: 30px;
  height: 20px;
  background-repeat: no-repeat; }
`

const StyledLink = styled(Link)`
  text-decoration: none!important;
`

export const LanguageSelector = ({ location }) => {
  console.log(location)
  const isFrench = location.pathname.match(/\/fr\//)
  const urlStripped = location.pathname.match(/\/en\//) ? location.pathname.slice(3) : location.pathname
  let url = isFrench ? "/en" + location.pathname.slice(3) : "/fr" + urlStripped
  console.log(url, location.pathname)
  url = url === '/en/' ? '/' : url
  return isFrench ? <FlagWrapper>
    <StyledLink to={url}>
    🇬🇧
    </StyledLink>
  </FlagWrapper> : <FlagWrapper>
    <StyledLink to={url}>
    🇫🇷
    </StyledLink>
  </FlagWrapper>
};
